@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    scroll-behavior: smooth;
  }
  
  body {
    @apply font-body text-text bg-background;
  }

  h1, h2, h3, h4, h5, h6 {
    @apply font-heading;
  }
} 